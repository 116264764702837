import { Injectable } from "@angular/core";
import { ILogin } from "../interfaces/login.interface";


@Injectable({
  providedIn: 'root'
})
export class DataService {
  showErrorInUI = false;
  constructor() { }

  clearSessionStorage(): void {
    sessionStorage.clear();
  }
  clearLocalStorage(): void {
    localStorage.clear();
  }
  deleteLocalSessionItem(field: string){
    localStorage.removeItem(field)
  }

  setSessionStorageItem(field: string, value: any): void {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    sessionStorage.setItem(field, value)
  }
  setLocalStorageItem(field: string, value: any): void {
    if (typeof value !== 'string') {
      value = value.toString()
    }
    localStorage.setItem(field, value)
  }

  getSessionStorageItem(field: string): string | object | any[] {
    const fieldData = sessionStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }

  getLocalStorageItem(field: string): any {
    const fieldData = localStorage.getItem(field) || '';
    try {
      const parsedData = JSON.parse(fieldData);
      if (Array.isArray(parsedData)) {
        return parsedData;
      } else if (typeof parsedData === 'object') {
        return parsedData;
      } else if (typeof parsedData === 'string') {
        return parsedData;
      }
      return fieldData;
    } catch (error) {
      return fieldData;
    }
  }
   saveLoginResponse(loginResponse: any ,emailId?: any) {
    if(loginResponse.access_token){
      localStorage.setItem('access_token', loginResponse.access_token);
    localStorage.setItem('user_id', loginResponse.user_id);
     localStorage.setItem('refresh_token', loginResponse.refresh_token);
    localStorage.setItem('user_name', loginResponse.user_name);
    localStorage.setItem('user_type', loginResponse.user_type);
     localStorage.setItem('tenant_name', loginResponse.tenant_name);
     localStorage.setItem('expires_in', loginResponse.expires_in);
     if(emailId){
      localStorage.setItem('emailId', emailId);
     }
    }
    
  }

}
